/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import AuthLayout from './layouts/Auth.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from 'vuex';
import VueCookies from 'vue-cookies'
// import './plugins/click-away'
import 'vue-select/dist/vue-select.css';
import { AutoComplete } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import './scss/app.scss';
import moment from 'moment-timezone';

Vue.prototype.$moment = moment;

Vue.use(Antd);
Vue.use(VueAxios, axios);
Vue.use(VueCookies)
Vue.use(AutoComplete)

import store from './store'

Vue.config.productionTip = false

// axios.defaults.headers.common['Authorization'] = `Bearer ${store.getters.getToken}`;
axios.interceptors.request.use(
  function(config) {
    const token = store.getters.getToken;
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

// Adding template layouts to the vue components.
Vue.component("layout-auth", AuthLayout);
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')