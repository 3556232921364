import Vue from 'vue'
import Vuex from 'vuex';
import VueCookies from 'vue-cookies'

Vue.use(Vuex);
Vue.use(VueCookies);

const store = new Vuex.Store({
  state: {
    token: null,
    user: {},
    addresses: [],
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUser(state, user) {
      state.user = user
    },
    setAddresses(state, addresses){
      state.addresses = addresses
    }
  },
  actions: {
    setTokenAction({ commit }, token) {
      commit('setToken', token);
    },
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setAddresses({ commit }, addresses){
      commit('setAddresses', addresses)
    }
  },
  getters: {
    getToken: state => state.token ? state.token : localStorage.getItem('token'),
    getUser: state => state.user ? state.user : localStorage.getItem('user'),
    getAddresses: state => state.addresses.length ? state.addresses : []
  }
});

export default store